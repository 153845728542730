import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

import { Card } from '@fairwindsops/ui-components';

import { IRoute, IRouter } from '~utils/global.types.react';

import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { strings } from '~utils/strings';
import { Title } from '~utils/texts.react';

import './Terms.react.scss';

type SignTermsProps = {
  route: IRoute;
  router: () => IRouter;
};

const SignTerms = ({ route, router }: SignTermsProps) => {
  const redir = route?.query?.redir || '/orgs/';
  const [isSigned, setIsSigned] = useState<boolean>(false);

  const signAgreement = async () => {
    try {
      const result = await sendRequest(
        'POST',
        '/v0/user/sign-customer-agreement',
        { data: { tos: isSigned.toString() } },
        null,
      );

      if (result && result.Success) {
        router().push({ path: redir });
      }
    } catch (e) {
      logger.logError('error_updating_tos_agreement', e);
    }
  };

  return (
    <Card className="update-terms">
      <Card.Body padded>
        <h1 className={Title({ size: 'sm', bottomMargin: strings.textStyling.lgBottom })}>
          Our Terms of Service have changed
        </h1>
        <p>
          The Fairwinds Insights Terms of Service has changed since the last time you logged in.
          Please{' '}
          <a href="https://www.fairwinds.com/insights-terms-and-conditions" target="_blank">
            review the latest terms of service
          </a>
          , and check the box below to continue.
        </p>
        <Form
          onSubmit={(e) => {
            signAgreement();
            e.preventDefault();
          }}
          noValidate
        >
          <Form.Check type="checkbox" className="update-terms__form">
            <Form.Check.Input
              type="checkbox"
              onChange={() => setIsSigned((prevState) => !prevState)}
            />
            <Form.Check.Label>
              I agree to the terms of the{' '}
              <a href="https://www.fairwinds.com/insights-terms-and-conditions" target="_blank">
                Terms of Service
              </a>
            </Form.Check.Label>
          </Form.Check>
          <Button
            className="update-terms__submit-button"
            type="submit"
            variant="primary"
            disabled={!isSigned}
          >
            {strings.general.Submit}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default SignTerms;
